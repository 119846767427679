@font-face {
  font-family: 'Oxygen';
  src: local('Oxygen'), url(./fonts/Oxygen-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-VariableFont_slntwght.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
